import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div class="max-w-screen-lg mx-auto">
        <ul className="flex justify-around bg-neutral-100 py-4">
          <li>
            <Link to="/terms">Terms of Service</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
          <li>
            <a href="mailto:support@superworkday.com">Contact Us</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
