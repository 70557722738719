const apps = [
  {
    name: "Schedule Recurring Tasks",
    subtext: "Now you can schedule recurring tasks in your monday.com boards in less than 1 minute",
    ytVideoId: "DfI1NW0rfvA",
    clientId: "813af5547017c7690503108da29dd4ba",
  },
  {
    name: "Google Calendar Sync",
    subtext: "Now you can sync your monday.com boards to your Google calendar in press of a button",
    ytVideoId: "qg_tYfbkGBM",
    clientId: "ef5eb3e5fbb2ad312350e77f5c0597b5",
  },
  {
    name: "Special Automations",
    subtext: "Supercharge you monday workflows with special powerful automations",
    ytVideoId: "qzyHAmJgHY4",
    clientId: "d20cc1f777a167bc1d542cb6a69e449d",
  },
];

export default apps;
