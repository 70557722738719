import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import AppPage from "./pages/AppPage";
import HowToUse from "./pages/HowToUse";
import Demo from "./pages/Demo";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/:appId" element={<AppPage />} />
        <Route path="/:appId/how-to-use" element={<HowToUse />} />
        <Route path="/:appId/demo" element={<Demo />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
