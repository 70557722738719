import { Link } from "react-router-dom";
import icon from "../images/icon.png";

const Header = () => {
  return (
    <header className="fixed w-full p-4 bg-white border-b border-b-neutral-100">
      <div class="max-w-screen-lg mx-auto">
        <div class="flex items-center gap-4 text-[2rem]">
          <img src={icon} className="w-[50px]" />
          <Link to="/">Super Workday</Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
