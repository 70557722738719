import apps from "../data/apps";
import heroImage from "../images/office-work.jpg";

const Home = () => {
  return (
    <main className="pt-40 pb-20">
      <div class="max-w-screen-lg mx-auto">
        <div class="flex items-center justify-between">
          <div class="hero-left">
            <div>
              <h1 className="text-[4rem] font-bold">
                Powerful Apps
                <br />
                For Your
                <br />
                Workflows
              </h1>
            </div>

            <p className="mt-8 text-2xl">
              Super-charge your monday.com
              <br />
              boards and workflows with our apps
            </p>
          </div>

          <div class="w-[40%]">
            <img src={heroImage} alt="Office Work" className="w-full rounded-2xl" />
          </div>
        </div>

        {apps.map((app) => {
          return (
            <div className="flex flex-col gap-8 justify-center items-center text-center mt-40">
              <h2 className="text-4xl">{app.name}</h2>
              <p className="text-xl">{app.subtext}</p>

              <iframe
                width="896"
                height="504"
                src={`https://www.youtube.com/embed/${app.ytVideoId}`}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                className="my-4 rounded-3xl"
              ></iframe>

              <a
                href={`https://auth.monday.com/oauth2/authorize?client_id=${app.clientId}&response_type=install`}
              >
                <img
                  alt="Add to monday.com"
                  src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                  className="h-[60px]"
                />
              </a>
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default Home;
